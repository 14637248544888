export const Base_URL = "https://tpm.wowizer.com/api";
export const Kibana_URL= "https://tpm.wowizer.com/kibana/"
// export const Base_URL = "https://tpm.wowizer.ai/api";
// export const Kibana_URL = "http://localhost:5601/kibana/";

export const Login_User = "/auth/login"

export const Logout_User = "/auth/logout"

export const Register_User = "/auth/register"

export const Update_Password = "/auth/update_password/"

export const Reset_Password = "/auth/reset_password/"

export const All_UserInfo = "/auth/get_all_users"

export const Admin_Approval = "/auth/signup"

export const Account_Expiry = "/auth/update/account_expiry"

export const Account_Status = "/auth/update/account_status"

export const File_Upload = "/users/fileupload/"

export const User = "/users/me"

export const Upload_History = "/uploadhistory/"