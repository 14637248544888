import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Base_URL, File_Upload, Upload_History } from "../constants";
import useFullPageLoader from '../components/hooks/useFullPageLoader';
import swal from 'sweetalert';
import axios from 'axios';

const fileTypes = ["ZIP"];

function Uploader() {
  const [file, setFile] = useState(null);
  const [inprogress, setInProgress] = useState(false);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  
  const maxFileSizeMB = 500;

  const handleChange = (file) => {
    setFile(file);

    if (file.size > maxFileSizeMB * 1024 * 1024) {
      swal({
        icon: 'error',
        title: 'Error',
        text: `File size should not exceed ${maxFileSizeMB} MB.`
      });
      return;
    }

    swal({
      title: "Enter Upload Tag",
      text: "Give any name to identify your upload file.",
      content: {
        element: "input",
      },
    })
    .then((value) => {
      if (value === null || value.trim() === "") {
        swal({
          icon: 'error',
          title: 'Error',
          text: 'Please enter a value.'
        }).then(() => {
          window.location.reload();
        });
        return;
      }

      value = value.trim();

      if (/^\d/.test(value)) {
        swal({
          icon: 'error',
          title: 'Error',
          text: 'Upload tag should not start with a numeric character.'
        }).then(() => {
          window.location.reload();
        });
        return;
      }
      
      showLoader();
      const tenant = localStorage.getItem('tenant_name');
      
      const data = new FormData();
      data.append('productname', 'QlikSense_2021');
      data.append('qlikversion', 'QV21');
      data.append('file1', file);
      data.append('identifier', value);
    
      var config = {
        method: 'post',
        url: Base_URL + File_Upload + tenant,
        data : data
      };

      axios(config)
      .then(function (response) {
        hideLoader();
        swal({
          icon: 'success',
          title: 'File Uploaded Successfully'
        }).then(function() {
          window.location.href = '/anomaly';
        });
      })
      .catch(function (error) {
        console.log(error);
      });
    });  
  };

  const fetchData = async (page) => {
    const tenant = localStorage.getItem('tenant_name');
  
    var config = {
      method: 'get',
      url: Base_URL + Upload_History + tenant + page,
      headers: {},
    };
  
    axios(config)
      .then(function (response) {
        const UploadHistory = response.data.data.uploadhsitory.map((element) => {
          return {
            id: element.uploadTag,
            uploadTime: element.uploadTime,
            ingestionStatus: element.ingestionStatus,
            qlikVersion: element.qlikVersion,
            deleteStatus: element.deleteStatus,
          };
        });
        const hasInProgress = UploadHistory.some(record => record.ingestionStatus === 'in_progress' && record.deleteStatus === false);
        setInProgress(hasInProgress);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  React.useEffect(() => {
    fetchData('');
  }, []);

  return (
    <>
      {
        localStorage.getItem('userRole') === "trialuser" ?
        <>
          <FileUploader disabled />
        </>
        :
        <>
        {
          inprogress === false ? 
          <div style={{ marginTop: '10px' }}>
            <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
          {loader}
          </div>
          :
          <div style={{ marginTop: '10px' }}>
          <FileUploader disabled />
          {loader}
          </div>
        }
        </>
      }
    </>
  );
}

export default Uploader;